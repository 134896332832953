import React from "react";
import Layout from "../layouts/Layout";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const StyledStaticPage = styled.div`
  .static-page {
    &__title {
      text-align: center;
    }

    &__slug a {
      text-decoration: none;
      color: #000;
      &:hover {
        text-decoration: underline;
      }
    }

    &__header {
      height: 300px;

      @media(max-width: 768px) {
        .wrapper {
          justify-content: center !important;
          text-align: center;
        }
      }
      
      background: -webkit-linear-gradient(
        273deg,
        rgba(242, 242, 242, 0.7) 0%,
        rgba(206, 212, 216, 0.7) 65%,
        rgba(206, 212, 216, 0.698) 100%
      );
      background: -o-linear-gradient(
        273deg,
        rgba(242, 242, 242, 0.7) 0%,
        rgba(206, 212, 216, 0.7) 65%,
        rgba(206, 212, 216, 0.698) 100%
      );
      background: -ms-linear-gradient(
        273deg,
        rgba(242, 242, 242, 0.7) 0%,
        rgba(206, 212, 216, 0.7) 65%,
        rgba(206, 212, 216, 0.698) 100%
      );
      background: -moz-linear-gradient(
        273deg,
        rgba(242, 242, 242, 0.7) 0%,
        rgba(206, 212, 216, 0.7) 65%,
        rgba(206, 212, 216, 0.698) 100%
      );
      background: linear-gradient(
        177deg,
        rgba(242, 242, 242, 0.7) 0%,
        rgba(206, 212, 216, 0.7) 65%,
        rgba(206, 212, 216, 0.698) 100%
      );

      .intro {
        color: #527191;
      }

      .title {
        @media(min-width: 768px) {
          padding-left: 3em;
        }
      }

      .content {
        @media(max-width: 768px) {
          flex-direction: column-reverse;
          align-items: center;
        }
      }
    }
  }

  .section {
    display: flex;
    align-items: center;
    @media (max-width: 992px) {
      flex-direction: column;
    }
    &__title {
      color: #e7c700;
      font-size: 30px;
      min-width: 400px;
      align-self: flex-start;
      text-align: left;
      padding-top: 1em;
    }
  }

  .items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 100px;

    &__block {
      padding: 0 2em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 3em;
      flex: 50%;
    }

    &__title {
      margin: auto;
      font-weight: 500;
      font-size: 18px;
    }

    &__description {
      max-width: 600px;
      text-align: center;
    }
  }
`;

export default function comeFunziona({ className }) {
  return (
    <Layout 
      helmetTitle="Scopri come funziona la formula 100% Online - Prestitifaidate"
      helmetDescription="Scopri come funziona la formula 100% Online: conveniente, veloce, semplice, facile, sicura e trasparente. E con i nostri consulenti sempre a tua disposizione."
    >
      <StyledStaticPage id="page-content">
        <div className="static-page__header">
          <div className="wrapper container d-flex align-items-center position-relative justify-content-between h-100">
            <div className="d-flex align-items-center content">
              <StaticImage
                src="../assets/images/testatina-come-funziona.png"
                className={className}
                alt="Come funziona"
                placeholder="blurred"
                layout="fixed"
                width={151}
                height={150}
                objectFit="contain"
                quality={75}
              />
              <div className="d-flex flex-column title mb-3 mb-lg-0">
                <span className="fw-bold fs-1">Come funziona</span>
                <span className="intro">
                  Come Funziona il Prestito Che Funziona
                </span>
              </div>
            </div>
          </div>
        </div>
        <div id="page-content" className="container mx-auto">
          <div className="static-page__slug mb-5 mt-4 fs-5">
            {" "}
            <Link to="/" className="fw-bold">
              HOME
            </Link>{" "}
            &gt; <Link to="/come-funziona/">Come funziona</Link>
          </div>

          <h1 className="static-page__title mb-5 display-5 fw-bold">
            Come funziona
          </h1>
          <div className="static-page__content">
            <section className="section">
              <div className="section__content text-center">
                <p className="text-center">
                  Scopri come funziona la formula 100% online di
                  PrestitiFaidaTe.it. Ti offre solo tanti vantaggi: è
                  conveniente, veloce, semplice, facile, sicura e trasparente. E
                  soprattutto avrai sempre a tua disposizione i consulenti di
                  PrestitiFaidaTe.it e con il loro supporto potrai scegliere tra
                  i finanziamenti disponibili quello più adatto a soddisfare le
                  tue esigenze per realizzare i tuoi progetti personali o
                  familiari.
                </p>
                <div className="items">
                  <div className="items__block">
                    <StaticImage
                      src="../assets/images/step_1.png"
                      className="img-fluid mx-auto"
                      alt="Step 1"
                      placeholder="blurred"
                      layout="fixed"
                      width={250}
                      height={250}
                      quality={75}
                    />
                    <p className="items__description">
                      Sorridi, abbiamo già ricevuto il tuo form online completo.
                      Entro un'ora, otterrai l'esito della tua richiesta di
                      prestito. Ci sei quasi, manca davvero poco... il tuo sogno
                      sta per avverarsi!
                    </p>
                  </div>

                  <div className="items__block">
                    <StaticImage
                      src="../assets/images/step_2.png"
                      className="img-fluid mx-auto"
                      alt="Step 2"
                      placeholder="blurred"
                      layout="fixed"
                      width={250}
                      height={250}
                    />
                    <p className="items__description">
                      Fai le foto al tuo documento di identità, alla tua busta
                      paga o al tuo cedolino pensione, poi inviale su WhatsApp
                      in tutta sicurezza. Ti serve solo assicurarti che tutto
                      sia leggibile. Semplice, no?
                    </p>
                  </div>

                  <div className="items__block">
                    <StaticImage
                      src="../assets/images/step_3.png"
                      className="img-fluid mx-auto"
                      alt="Step 3"
                      placeholder="blurred"
                      layout="fixed"
                      width={250}
                      height={250}
                    />
                    <p className="items__description">
                      La tua video consulenza gratuita inizia fra pochissimo. Il
                      nostro professionista specializzato ti contatterà a
                      momenti su WhatsApp. Mettiti comodo e preparati a ricevere
                      il tuo prestito sicuro e rapido 100% online.
                    </p>
                  </div>

                  <div className="items__block">
                    <StaticImage
                      src="../assets/images/step_4.png"
                      className="img-fluid mx-auto"
                      alt="Step 4"
                      placeholder="blurred"
                      layout="fixed"
                      width={250}
                      height={250}
                    />
                    <p className="items__description">
                      Ci sei riuscito, hai ottenuto il tuo prestito! Il nostro
                      bonifico è in dirittura d'arrivo. Entro dieci giorni
                      riceverai tutto l'importo richiesto sul tuo conto
                      corrente. Sai già come lo impiegherai, giusto?
                    </p>
                  </div>
                </div>
                <p className="my-5">
                  I tuoi progetti non aspettano. Realizzali adesso con il tuo
                  prestito super veloce! E vivi senza pensieri! Perché hai tutta
                  la sicurezza e la riservatezza della procedura online, non ti
                  servono garanzie né giustificativi di spesa. Fai avverare il
                  sogno di una vita, te lo meriti!
                </p>
              </div>
            </section>
          </div>
        </div>
      </StyledStaticPage>
    </Layout>
  );
}
